import {OnlinePaymentStatus, POSPaymentStateStatus} from '@/constants'
import translations from './translations'

translations.add('es', {
    transactions: 'Actas',
    tooltipText: {
        [POSPaymentStateStatus.successful]:
            'Incluye transacciones con los siguientes estados: Autorizado, Cobrado, Reembolsado, Cancelado, Reversión de cargo, Segundo contracargo - perdido',
        [OnlinePaymentStatus.charge]:
            'Incluye transacciones donde el titular de la tarjeta ha sido cobrado, pero el pago todavía está en proceso o pendiente de confirmación para liquidación',
        [OnlinePaymentStatus.auth]:
            'Incluye transacciones pendientes que están esperando autorización o han sido preautorizadas',
        [OnlinePaymentStatus.refund]: 'Incluye transacciones reembolsadas y acreditadas'
    },
    comparedToPreviousPeriod: (period) => `Comparado con ${period}`
})
