import {CardSchemeType} from '@/constants'
import rootTranslations from '@/translations'

export const convertCardScheme = (strCardScheme: string): CardSchemeType => {
    switch (String(strCardScheme).trim().toLocaleLowerCase()) {
        case 'amex':
        case 'amexcard':
        case 'americanexpress':
        case 'american express':
            return CardSchemeType.Amex
        case 'dci':
        case 'diners':
        case 'dinersclub':
        case 'diners club':
            return CardSchemeType.Diners
        case 'mc':
        case 'mastercard':
        case 'master card':
            return CardSchemeType.Mastercard
        case 'upi':
        case 'unionpay':
        case 'union pay':
            return CardSchemeType.UnionPay
        case 'visa':
        case 'visacard':
        case 'visa card':
            return CardSchemeType.Visa

        default:
            return null
    }
}

export const convertCardSchemeToEcom = (cardScheme: CardSchemeType) => {
    switch (cardScheme) {
        case CardSchemeType.Amex:
            return 'AmericanExpress'
        case CardSchemeType.Diners:
            return 'DinersClub'
        case CardSchemeType.Mastercard:
            return 'MasterCard'
        case CardSchemeType.UnionPay:
            return 'UnionPay'
        case CardSchemeType.Visa:
            return 'VISA'
    }
}

export const convertCardSchemeToPOS = (cardScheme: CardSchemeType) => {
    switch (cardScheme) {
        case CardSchemeType.Amex:
            return 'amex'
        case CardSchemeType.Diners:
            return 'diners'
        case CardSchemeType.Mastercard:
            return 'mastercard'
        case CardSchemeType.UnionPay:
            return 'upi'
        case CardSchemeType.Visa:
            return 'visa'
        default:
            return null
    }
}

export const getCardSchemeLabel = (strCardScheme: string) => {
    const cardScheme = convertCardScheme(strCardScheme)
    return rootTranslations().constants.cardScheme[cardScheme] || cardScheme || strCardScheme
}

export const getCardSchemeIconForChart = (strCardScheme: string) => {
    const cardScheme = convertCardScheme(strCardScheme)

    switch (cardScheme) {
        case CardSchemeType.Visa:
            return 'visa_colored'
        case CardSchemeType.Mastercard:
            return 'mastercard_colored'
        case CardSchemeType.Amex:
            return 'amex_colored'
        case CardSchemeType.Diners:
            return 'diners_colored'
        case CardSchemeType.UnionPay:
            return 'union_pay_colored'
        default:
            return null
    }
}

const cardSchemes = [
    CardSchemeType.Visa,
    CardSchemeType.Mastercard,
    CardSchemeType.Amex,
    CardSchemeType.UnionPay,
    CardSchemeType.Diners
]

export const posCardSchemes = cardSchemes.map((scheme) => ({
    value: convertCardSchemeToPOS(scheme),
    label: rootTranslations().constants.cardScheme[scheme]
}))

export const ecomCardSchemes = cardSchemes.map((scheme) => ({
    value: convertCardSchemeToEcom(scheme),
    label: rootTranslations().constants.cardScheme[scheme]
}))
