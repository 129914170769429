import React from 'react'
import {ColumnsType, ColumnType} from 'antd/es/table'
import moment from 'moment'
import {CardSchemeLabelWithIcon} from '@/components'
import {Currencies, DATE_FORMAT, TITLE_DAY_FORMAT, TIME_FORMAT} from '@/constants'
import {Settlement} from '@/stores/settlements/models/Settlement'
import {getAmountWithCurrency} from '@/utils'
import {GroupableItem} from '@/types/grid'
import translations from '../translations'
import styles from './styles.scss'
import rootTranslations from '@/translations/translations'

export type PosTransactionColumnType = ColumnType<GroupableItem<Settlement>>

export const TRANSACTION_DATE_COLUMN_KEY = 'transactionDate'
export const TRANSACTION_DATE_COLUMN_WIDTH = 170

export const dateToDayAndMonth = (rawDate: string) => {
    const date = moment(rawDate)
    const today = moment()
    if (today.format('D') === date.format('D')) {
        return translations().columnValues.today
    }
    return date.format(TITLE_DAY_FORMAT)
}

export function checkIfContainsDate(dateColumn: {}, date: string) {
    if (!dateColumn[date]) {
        dateColumn[date] = true
        return true
    }
    return false
}

export const generateColumns = (isMobile) => {
    const rest = restOfColumns(isMobile)

    return [
        getTransactionDateColumn(),
        ...rest.map((column) => ({
            ...column,
            onCell: (record) => {
                if (record.groupTitle) {
                    return {className: styles.groupHeader}
                }
                return undefined
            }
        }))
    ]
}

export const generatePerDayColumns = () => {
    return [
        {
            title: translations().columnsPerDay.transactionDate,
            key: 'transactionDate',
            dataIndex: 'transactionDate',
            width: 18,
            render: (value: string) => Boolean(value) && moment(value).format(DATE_FORMAT)
        },
        {
            title: translations().columnsPerDay.count,
            key: 'count',
            dataIndex: 'count',
            width: 10
        },
        {
            title: translations().columnsPerDay.amount,
            key: 'amount',
            dataIndex: 'amount',
            render: (value, object) => (
                <b>{getAmountWithCurrency(value, object.currency as Currencies)}</b>
            ),
            width: 20
        },
        {
            title: translations().columnsPerDay.acquirerFee,
            key: 'feeAmount',
            dataIndex: 'feeAmount',
            render: (value, object) => (
                <b>{getAmountWithCurrency(value, object.currency as Currencies)}</b>
            ),
            width: 16
        },
        {
            title: translations().columnsPerDay.amountToMerchant,
            key: 'amountToMerchant',
            dataIndex: 'amountToMerchant',
            render: (value, object) => (
                <b>{getAmountWithCurrency(value, object.currency as Currencies)}</b>
            ),
            width: 16
        },
        {
            title: translations().columnsPerDay.settlementDate,
            key: 'settlementDate',
            dataIndex: 'settlementDate',
            width: 18,
            render: (value) => value && moment(value).format(DATE_FORMAT)
        }
    ]
}

export const getTransactionDateColumn = (): PosTransactionColumnType => ({
    title: translations().columns.transactionDate,
    dataIndex: TRANSACTION_DATE_COLUMN_KEY,
    colSpan: 1,
    key: TRANSACTION_DATE_COLUMN_KEY,
    width: TRANSACTION_DATE_COLUMN_WIDTH,
    fixed: 'left',
    onCell: (record) => {
        if (record.groupTitle) {
            return {className: styles.groupHeader}
        }
        return undefined
    },
    render: (date: string, row) => {
        if (row.groupTitle) {
            return <div> {row.groupTitle}</div>
        }
        return date ? moment(date).format(`${DATE_FORMAT} | ${TIME_FORMAT}`) : null
    }
})

export const restOfColumns = (isMobile = false): ColumnsType<Settlement> => {
    return [
        {
            title: translations().columns.settlementDate,
            dataIndex: 'settlementDate',
            key: 'settlementDate',
            fixed: isMobile ? undefined : 'left',
            width: 160,
            render: (value: string) => Boolean(value) && moment(value).format(DATE_FORMAT)
        },
        {
            title: translations().columns.address,
            dataIndex: 'address',
            key: 'address',
            width: 180
        },
        {
            title: translations().columns.postcode,
            dataIndex: 'postcode',
            key: 'postcode',
            width: 150
        },
        {
            title: translations().columns.mid,
            dataIndex: 'mid',
            key: 'mid',
            width: 150
        },
        {
            title: translations().columns.terminalNumber,
            dataIndex: 'terminalNumber',
            key: 'terminalNumber',
            width: 150
        },
        {
            title: translations().columns.amount,
            dataIndex: 'amount',
            key: 'amount',
            width: 200,
            render: (item, object) => (
                <b>{getAmountWithCurrency(item, object.currency as Currencies)}</b>
            )
        },
        {
            title: translations().columns.settlementType,
            dataIndex: 'settlementType',
            key: 'amount',
            width: 200
        },
        {
            title: translations().columns.totalFee,
            dataIndex: 'totalFee',
            key: 'totalFee',
            width: 150,
            render: (item, object) => (
                <b>{getAmountWithCurrency(item, object.currency as Currencies)}</b>
            )
        },
        {
            title: translations().columns.interchangeFee,
            dataIndex: 'interchangeFee',
            key: 'interchangeFee',
            width: 150,
            render: (item, object) => (
                <b>{getAmountWithCurrency(item, object.currency as Currencies)}</b>
            )
        },
        {
            title: translations().columns.schemeFee,
            dataIndex: 'schemeFee',
            key: 'schemeFee',
            width: 120,
            render: (item, object) => (
                <b>{getAmountWithCurrency(item, object.currency as Currencies)}</b>
            )
        },
        {
            title: translations().columns.processingFee,
            dataIndex: 'processingFee',
            key: 'processingFee',
            width: 120,
            render: (item, object) => (
                <b>{getAmountWithCurrency(item, object.currency as Currencies)}</b>
            )
        },
        {
            title: translations().columns.gatewayFee,
            dataIndex: 'gatewayFee',
            key: 'gatewayFee',
            width: 120,
            render: (item, object) => (
                <b>{getAmountWithCurrency(item, object.currency as Currencies)}</b>
            )
        },
        {
            title: translations().columns.authorisationFee,
            dataIndex: 'authorisationFee',
            key: 'authorisationFee',
            width: 135,
            render: (item, object) => (
                <b>{getAmountWithCurrency(item, object.currency as Currencies)}</b>
            )
        },
        {
            title: translations().columns.blendedFee,
            dataIndex: 'blendedFee',
            key: 'blendedFee',
            width: 120,
            render: (item, object) => (
                <b>{getAmountWithCurrency(item, object.currency as Currencies)}</b>
            )
        },
        {
            title: translations().columns.refundFee,
            dataIndex: 'refundFee',
            key: 'refundFee',
            width: 120,
            render: (item, object) => (
                <b>{getAmountWithCurrency(item, object.currency as Currencies)}</b>
            )
        },
        {
            title: translations().columns.amountToSettle,
            dataIndex: 'amountToSettle',
            key: 'amountToSettle',
            width: 160,
            render: (item, object) => (
                <b>{getAmountWithCurrency(item, object.currency as Currencies)}</b>
            )
        },
        {
            title: translations().columns.sortCode,
            dataIndex: 'sortCode',
            key: 'sortCode',
            width: 100
        },
        {
            title: translations().columns.accountNumber,
            dataIndex: 'accountNumber',
            key: 'accountNumber',
            width: 160
        },
        {
            title: translations().columns.operation,
            dataIndex: 'operation',
            key: 'operation',
            width: 110
        },
        {
            title: translations().columns.transactionType,
            dataIndex: 'transactionType',
            key: 'transactionType',
            width: 160,
            render: (item) => rootTranslations().transactionTypes[item?.toUpperCase()] || item
        },
        {
            title: translations().columns.transactionId,
            dataIndex: 'transactionId',
            key: 'transactionId',
            width: 190
        },
        {
            title: translations().columns.merchantReference,
            dataIndex: 'merchantReference',
            key: 'merchantReference',
            width: 180
        },
        {
            title: translations().columns.paymentMethod,
            dataIndex: 'cardMask',
            key: 'cardMask',
            width: 160,
            render: (item, settlement) => {
                if (!item) {
                    return ''
                }

                return <CardSchemeLabelWithIcon cardScheme={settlement.cardScheme} title={item} />
            }
        },
        {
            title: translations().columns.cardholderName,
            dataIndex: 'cardholderName',
            key: 'cardholderName',
            width: 150
        },
        {
            title: translations().columns.cardType,
            dataIndex: 'cardType',
            key: 'cardType',
            width: 120
        },
        {
            title: translations().columns.issuerRegion,
            dataIndex: 'issuerRegion',
            key: 'issuerRegion',
            width: 150
        },
        {
            title: translations().columns.issuerCountry,
            dataIndex: 'issuerCountry',
            key: 'issuerCountry',
            width: 140
        },
        {
            title: translations().columns.isCorporateCard,
            dataIndex: 'isCorporateCard',
            key: 'isCorporateCard',
            width: 170,
            render: (value) =>
                value === undefined || value === null
                    ? undefined
                    : value === true
                      ? translations().columnValues.corporate
                      : translations().columnValues.nonCorporate
        },
        {
            title: translations().columns.captureMethod,
            dataIndex: 'captureMethod',
            key: 'captureMethod',
            width: 150,
            render: (item) => rootTranslations().constants.captureMethods[item]
        }
    ]
}
