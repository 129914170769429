import moment from 'moment'
import numeral from 'numeral'
import {error} from 'dna-common'
import {Currencies} from '@/constants'
import config from '../../config'

export * from './notifications'
export * from './ecospend'
export * from './filter'
export * from './object-utils'
export * from './url'
export * from './string-utils'

export const drawCtxCenterText = (chart, elementName: string) => {
    const ctx = chart.ctx

    // element configs
    const centerConfig = chart.config.options.elements[elementName]

    const {
        fontStyle,
        fontSize,
        text,
        color,
        minFontSize,
        maxFontSize,
        sidePadding,
        lineHeight,
        textAlign,
        textBaseline,
        heightPadding
    } = centerConfig

    const sidePaddingCalculated = (sidePadding / 100) * (100 * 2)
    const heightPaddingCalculated = (heightPadding / 100) * (100 * 2)
    ctx.font = `${fontSize}px ${fontStyle}`

    const stringWidth = ctx.measureText(text).width
    // element width with padding
    const elementWidth = 100 * 2 - sidePaddingCalculated

    // ratio of how much the font can grow in width
    const widthRatio = elementWidth / stringWidth
    const newFontSize = Math.floor(30 * widthRatio)
    const elementHeight = 100 * 2 + heightPaddingCalculated

    // responsive font size
    let fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize)
    let wrapText = false

    if (minFontSize && fontSizeToUse < minFontSize) {
        fontSizeToUse = minFontSize
        wrapText = true
    }

    // setting font settings to text
    ctx.textAlign = textAlign
    ctx.textBaseline = textBaseline
    const centerX = (chart.chartArea.left + chart.chartArea.right) / 2
    let centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2 + heightPadding
    ctx.font = fontSizeToUse + 'px ' + fontStyle
    ctx.fillStyle = color

    // draw text if it's not wrapped
    if (!wrapText) {
        ctx.fillText(text, centerX, centerY)
        return
    }

    const {line, lines} = breakCtxWordsIntoMultipleLines(ctx, text, elementWidth)

    // text will be moved up depending on lineHeight and count of lines
    centerY -= (lines.length / 2) * lineHeight

    for (let n = 0; n < lines.length; n++) {
        ctx.fillText(lines[n], centerX, centerY)
        centerY += lineHeight
    }

    ctx.fillText(line, centerX, centerY)
}

export const breakCtxWordsIntoMultipleLines = (
    ctx,
    text: string,
    elementWidth: number
): {line: string; lines: string[]} => {
    const words = text.split(' ')
    let line = ''
    const lines = []

    // Break words up into multiple lines if necessary
    for (let n = 0; n < words.length; n++) {
        const testLine = line + words[n] + ' '
        const metrics = ctx.measureText(testLine)
        const testWidth = metrics.width
        if (testWidth > elementWidth && n > 0) {
            lines.push(line)
            line = words[n] + ' '
        } else {
            line = testLine
        }
    }

    return {
        line,
        lines
    }
}

export function camelCaseToSnakeCase(value: string) {
    return value.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase()
}

export function toPascalCase(string: string): string {
    return `${string}`.replace(/(\w)(\w*)/g, (g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase())
}

export function getAvailableDate() {
    return moment().add(1, 'minute')
}

export function generateInvoiceId(prefix = 'PL') {
    return `${prefix}-${new Date().getTime()}`
}

export function addZeroes(num, len = 2) {
    return num.toFixed(Math.max(((num + '').split('.')[1] || '').length, len))
}

export function isInteger(n) {
    return n.indexOf('.') === -1
}

export function tryParse<T = any>(str: string) {
    try {
        return JSON.parse(str) as T
    } catch (err) {
        error(err)
        return null
    }
}

export const range = (start: number, end: number) => {
    const result = []
    for (let i = start; i < end; i++) {
        result.push(i)
    }
    return result
}

export const disabledDateBeforeNow = (current) => {
    return current && current < getAvailableDate().startOf('day')
}

export const disabledTimeBeforeNow = (current) => {
    if (
        current?.isSame(moment(), 'year') &&
        current?.isSame(moment(), 'month') &&
        current?.isSame(moment(), 'day')
    ) {
        return {
            disabledHours: () => range(0, moment().get('hours')),
            disabledMinutes: () => {
                if (current.isSame(moment(), 'hour')) {
                    return range(0, moment().add(15, 'minutes').get('minute'))
                } else {
                    return null
                }
            }
        }
    } else {
        return {
            disabledHours: null,
            disabledMinutes: null
        }
    }
}

export function upperCaseFirstLetter(value: string) {
    return value ? value.charAt(0).toUpperCase() + value.slice(1) : value
}

export function decodeHtmlEntity(htmlEntityCode: string) {
    return htmlEntityCode.replace(/&#(\d+);/g, (match, decoded) => String.fromCharCode(decoded))
}

export function getCurrencySymbol(currency = config.currency): string {
    switch (currency) {
        case Currencies.GBP:
            return decodeHtmlEntity('&#163;')
        case Currencies.EUR:
            return decodeHtmlEntity('&#8364;')
        case Currencies.USD:
            return decodeHtmlEntity('&#36;')
        case Currencies.CNY:
            return decodeHtmlEntity('&#20803;')
        case Currencies.SEK:
            return 'SEK'
        case Currencies.ISK:
            return 'ISK'
        default:
            return currency
    }
}

export function getAmountWithCurrentCurrency(amount: string | number, currency?: Currencies) {
    return `${getCurrencySymbol(currency)}${numeral(amount).format('0.00')}`
}

export function getAmountWithCurrency(
    amount: string | number,
    currency: Currencies = config.currency
) {
    if (amount === null || amount === undefined) {
        return null
    }

    return Number(amount) < 0
        ? `-${getCurrencySymbol(currency)}${numberWithCommas(getAmount(Math.abs(+amount)))}`
        : `${getCurrencySymbol(currency)}${numberWithCommas(getAmount(amount))}`
}

export const getAmount = (amount: string | number) => numeral(amount).format('0.00')

export const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+\b)/g, ',')
}

export const round = (num: number, precision = 2) =>
    Math.round(num * Math.pow(10, precision)) / Math.pow(10, precision)

export const isMobile = {
    Android: function () {
        return navigator.userAgent.match(/Android/i)
    },
    BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i)
    },
    iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i)
    },
    Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i)
    },
    Windows: function () {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i)
    },
    any: function () {
        return (
            isMobile.Android() ||
            isMobile.BlackBerry() ||
            isMobile.iOS() ||
            isMobile.Opera() ||
            isMobile.Windows()
        )
    }
}

export const removeFirstOrLastPercent = (str: string): string => {
    if (!str) {
        return ''
    }

    let newStr = str

    if (str[0] === '%') {
        newStr = str.substring(1)
    }

    if (str[str.length - 1] === '%') {
        newStr = newStr.slice(0, -1)
    }

    return newStr
}

export const getObjectNestedKeys = (obj: object): string[] => {
    const set = new Set<string>()
    for (const key in obj) {
        if (obj[key]) {
            set.add(key)
        }
        if (typeof obj[key] === 'object') {
            getObjectNestedKeys(obj[key])
        }
    }

    return Array.from(set)
}
