import React from 'react'
import classNames from 'classnames'
import {getCardSchemeLabel} from '@/services/card-scheme'
import {CardSchemeIcon} from '@/components'
import {CardSchemeLabelWithIconProps} from './props'
import styles from './styles.scss'
import {CARD_MASK_NUMBER_WITH_ICON} from '@/constants/playwright-ids'

export const CardSchemeLabelWithIcon: React.FC<CardSchemeLabelWithIconProps> = (props) => {
    const {cardScheme, title: _title, showCardSchemeTitle, className} = props
    const title = showCardSchemeTitle ? getCardSchemeLabel(cardScheme) : _title

    return (
        <span
            className={classNames(styles.cardScheme, className)}
            data-test-id={CARD_MASK_NUMBER_WITH_ICON}
        >
            <CardSchemeIcon type={cardScheme} /> {title}
        </span>
    )
}
