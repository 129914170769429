import React from 'react'
import {Divider, Dropdown} from 'antd'
import classNames from 'classnames'

import {Icon} from '@/components/dumb/Icon'
import {ActionButtonProps} from './props'
import styles from './styles.scss'
import {ACTION_LINK, VIEW_ACTIONS_BUTTON} from '@/constants/playwright-ids'

export const ActionButtons: React.FC<ActionButtonProps> = (props) => {
    const {linkTitle, onLinkClick, onClick, menu} = props
    const hasLink = Boolean(onLinkClick && linkTitle)

    return (
        <span className={classNames(styles.actions, hasLink && styles.hasLink)}>
            {hasLink && (
                <>
                    <a
                        data-test-id={ACTION_LINK}
                        onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            onLinkClick()
                        }}
                        href='#'
                    >
                        {linkTitle}
                    </a>
                    <Divider type={'vertical'} />
                </>
            )}
            {menu && (
                <Dropdown overlay={menu}>
                    <Icon
                        data-test-id={VIEW_ACTIONS_BUTTON}
                        className={styles.ellipsis}
                        onClick={(e) => e.preventDefault()}
                        type={'ellipsis'}
                    />
                </Dropdown>
            )}
            {!menu && <Icon className={styles.ellipsis} onClick={onClick} type={'ellipsis'} />}
        </span>
    )
}
