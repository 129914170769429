import React from 'react'
import {List} from 'antd'
import {getAmountWithCurrency, isEmptyOrSpaces} from '@/utils'
import {ChargebackType} from '@/api'
import {Currencies, ChargebackStatus} from '@/constants'
import {DATE_FORMAT} from '@/stores/chargebacks/ChargebacksTableStore/constants'
import {Card, Description, DateTimeLabel, TableStatus, CardSchemeLabelWithIcon} from '@/components'
import {TagType} from '@/components/dumb/Tag/props'
import {MobileListProps} from './props'

import appTranslations from '@/translations'
import translations from './translations'
import styles from './styles.scss'

export const ChargebacksTableMobile: React.FC<MobileListProps<ChargebackType>> = ({
    isLoading,
    dataSource,
    listExtra
}) => {
    return (
        <List
            loading={isLoading}
            itemLayout={'horizontal'}
            dataSource={dataSource}
            renderItem={(item) => {
                const {
                    id,
                    amount,
                    currency,
                    chargebackDate,
                    transactionDate,
                    status,
                    reasonCodeCategory,
                    cardScheme,
                    cardMask
                } = item

                return (
                    <Card
                        key={id}
                        title={
                            <strong>{getAmountWithCurrency(amount, currency as Currencies)}</strong>
                        }
                        extra={listExtra(item)}
                        className={styles.listItem}
                    >
                        <Description
                            dataSource={[
                                {
                                    label: translations().chargebackDate,
                                    key: 'chargebackDate',
                                    render: () => (
                                        <DateTimeLabel
                                            date={chargebackDate}
                                            dateFormat={DATE_FORMAT}
                                        />
                                    )
                                },
                                {
                                    label: translations().transactionDate,
                                    key: 'transactionDate',
                                    render: () => (
                                        <DateTimeLabel
                                            date={transactionDate}
                                            dateFormat={DATE_FORMAT}
                                        />
                                    )
                                },
                                {
                                    label: translations().status,
                                    key: 'status',
                                    render: () =>
                                        !isEmptyOrSpaces(status) ? (
                                            <TableStatus
                                                statusProps={{
                                                    status:
                                                        appTranslations().constants
                                                            .chargebackStatus[status] || status,
                                                    type:
                                                        status ===
                                                        ChargebackStatus['chargeback-reversal']
                                                            ? TagType.Success
                                                            : TagType.Default,
                                                    iconType: 'refund'
                                                }}
                                            />
                                        ) : null
                                },
                                {
                                    label: translations().reason,
                                    key: 'reasonCodeCategory',
                                    render: () =>
                                        appTranslations().constants.reasonCodeCategory[
                                            reasonCodeCategory
                                        ] || reasonCodeCategory
                                },
                                {
                                    label: translations().paymentMethod,
                                    key: 'paymentMethod',
                                    render: () => (
                                        <CardSchemeLabelWithIcon
                                            cardScheme={cardScheme}
                                            title={cardMask}
                                        />
                                    )
                                }
                            ]}
                        />
                    </Card>
                )
            }}
        />
    )
}
