import React from 'react'
import {Icon} from '@/components'
import {CardSchemeType} from '@/constants'
import {convertCardScheme} from '@/services/card-scheme'
import translations from './translations'
import {CartSchemeIconProps} from './props'
import {AMEX, DINNERS_CLUB, MASTERCARD, UNION_PAY, VISA} from '@/constants/playwright-ids'

export const CardSchemeIcon: React.FC<CartSchemeIconProps> = (props) => {
    const {className, type: strType} = props

    if (!strType) return null

    const type = convertCardScheme(strType)

    switch (type) {
        case CardSchemeType.Visa:
            return <Icon type={'visa'} className={className} data-test-id={VISA} />
        case CardSchemeType.Amex:
            return <Icon type={'amex'} className={className} data-test-id={AMEX} />
        case CardSchemeType.Mastercard:
            return <Icon type={'mastercard'} className={className} data-test-id={MASTERCARD} />
        case CardSchemeType.UnionPay:
            return <Icon type={'union_pay'} className={className} data-test-id={UNION_PAY} />
        case CardSchemeType.Diners:
            return <Icon type={'diners'} className={className} data-test-id={DINNERS_CLUB} />
        default:
            return <>{translations().other}</>
    }
}
