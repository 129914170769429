import {OnlinePaymentStatus, POSPaymentStateStatus} from '@/constants'
import translations from './translations'

translations.add('el', {
    transactions: 'Συναλλαγές',
    tooltipText: {
        [POSPaymentStateStatus.successful]:
            'Περιλαμβάνει συναλλαγές με τις εξής καταστάσεις: Εξουσιοδοτημένες, Χρεωμένες, Επιστραφείσες, Ακυρωμένες, Αντιστροφή χρέωσης, Δεύτερη αντιστροφή χρέωσης - απωλεσθείσα',
        [OnlinePaymentStatus.charge]:
            'Περιλαμβάνει συναλλαγές όπου ο κάτοχος της κάρτας έχει χρεωθεί, αλλά η πληρωμή είναι ακόμα υπό επεξεργασία ή επιβεβαίωση για εκκαθάριση',
        [OnlinePaymentStatus.auth]:
            'Περιλαμβάνει εκκρεμείς συναλλαγές που αναμένουν έγκριση ή έχουν προεγκριθεί',
        [OnlinePaymentStatus.refund]: 'Περιλαμβάνει επιστραφείσες και πιστωμένες συναλλαγές'
    },
    comparedToPreviousPeriod: (period) => `Σε σύγκριση με την ${period}`
})
