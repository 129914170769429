import {useEffect, useState} from 'react'
import {storage} from '@/services/storage'

const NOTIFICATIONS_STORAGE_KEY = 'notifications'

// Helper function to safely get notifications from storage
export const getStorageNotifications = () => {
    const storedNotifications = storage.get(NOTIFICATIONS_STORAGE_KEY)

    // Check if storedNotifications is null or undefined, return an empty object
    if (!storedNotifications) {
        return {}
    }
    try {
        // parse stored notifications
        const parsedData = JSON.parse(storedNotifications)
        // Check if parsedData is an object and not null, return it; otherwise, return an empty object
        return (parsedData && typeof parsedData === 'object' && !Array.isArray(parsedData))
            ? parsedData : {}
    } catch (e) {
        console.error('Error parsing notifications from storage:', e)
        return {}
    }
}

export const updateStorageNotifications = (allNotifications) => {
    storage.set(NOTIFICATIONS_STORAGE_KEY, JSON.stringify(allNotifications))
}

export const useStorageNotification = (notification, type: 'auth' | 'unauth', userId?: string) => {
    const { id, notificationContentAreaId } = notification || {}
    const storageKey = userId || 'unauthorized' // Default to 'unauthorized' for unauthenticated users

    const [notifications, setNotifications] = useState(() => {
        const allNotifications = getStorageNotifications()
        const userNotifications = allNotifications[storageKey] || { auth: [], unauth: [] }
        return userNotifications[type] || [] // Return notifications for the specific type
    })

    const [isClosed, setIsClosed] = useState(false)

    useEffect(() => {
        if (!id || !type) {
            return
        }

        const allNotifications = getStorageNotifications()

        // Ensure the user and type structure exists
        const userNotifications = allNotifications[storageKey] || { auth: [], unauth: [] }
        const currentNotifications = userNotifications[type] || []

        // Check if the notification already exists
        const existingNotification = currentNotifications.find((item) => item.id === id)
        if (existingNotification) {
            setIsClosed(existingNotification.isClosed)
        } else {
            // Add the new notification if it doesn't exist
            const newNotification = { id, notificationContentAreaId, isClosed: false }
            const updatedNotifications = [...currentNotifications, newNotification]

            userNotifications[type] = updatedNotifications
            allNotifications[storageKey] = userNotifications

            setNotifications(updatedNotifications)
            updateStorageNotifications(allNotifications)
        }
    }, [id, notificationContentAreaId, type, storageKey])

    const onCloseNotification = (notificationId) => {
        if (!notificationId || !type) {
            return
        }

        const allNotifications = getStorageNotifications()
        const userNotifications = allNotifications[storageKey] || { auth: [], unauth: [] }
        const currentNotifications = userNotifications[type] || []

        // Mark the notification as closed
        const updatedNotifications = currentNotifications.map((item) =>
            item.id === notificationId ? { ...item, isClosed: true } : item
        )

        userNotifications[type] = updatedNotifications
        allNotifications[storageKey] = userNotifications

        setNotifications(updatedNotifications)
        updateStorageNotifications(allNotifications)
        setIsClosed(true)
    }

    return { notifications, isClosed, onCloseNotification }
}
