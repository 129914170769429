import React from 'react'
import {Col, Row} from 'antd'
import {isEmpty} from 'dna-common'

import {CardSchemeLabelWithIcon, DateTimeLabel, Status} from '@/components'
import {Description} from '@/components/dumb/Description/props'

import {
    TransactionDetailsItemType,
    BasePOSTransactionType,
    POSTransactionType
} from '@/types/transactions'
import {getAmountWithCurrency} from '@/utils'

import {
    getPOSPaymentStatusName,
    getPOSPaymentStatusTagType,
    getPOSPaymentStatusIconType,
    getPOSTransactionType
} from '.'

import rootTranslations from '@/translations'
import translations from './translations'

export const orderMainDetails = (
    transaction: BasePOSTransactionType
): TransactionDetailsItemType => ({
    title: translations().paymentDetails,
    fields: [
        {
            label: translations().amount,
            value: getAmountWithCurrency(transaction.amount, transaction.currency)
        },
        {
            label: translations().date,
            value: `${transaction.transactionDate}`,
            render: () => <DateTimeLabel date={transaction?.transactionDate} />
        },
        {
            label: translations().status,
            value: getPOSPaymentStatusName(transaction.status),
            render: () => (
                <Row>
                    <Col>
                        <Status
                            status={getPOSPaymentStatusName(transaction.status)}
                            type={getPOSPaymentStatusTagType(transaction.status)}
                            iconType={getPOSPaymentStatusIconType(transaction.status)}
                        />
                    </Col>
                </Row>
            )
        },
        {
            label: translations().transaction.id,
            value: transaction.id,
            copyable: true
        },
        {
            label: translations().transaction.type,
            value: getPOSTransactionType(transaction)
        }
    ]
})

export const orderSecondaryDetails = (
    transaction: BasePOSTransactionType,
    newFields?: Description<string>[],
    fieldsIndex?: number
): TransactionDetailsItemType => {
    const fields = [
        {
            key: 'transactionDetails',
            label: translations().transaction.details,
            value: transaction.transactionDetails
        },
        {
            key: 'mid',
            label: translations().mid,
            value: transaction.mid
        },
        {
            key: 'terminalId',
            label: translations().terminalId,
            value: transaction.terminalId,
            copyable: true
        },
        {
            key: 'resultCode',
            label: translations().resultCode,
            value: transaction.returnCode
        },
        {
            key: 'description',
            label: translations().message,
            value: transaction.description
        },
        {
            key: 'cardMask',
            label: translations().card,
            value: transaction.cardMask,
            render: () => (
                <CardSchemeLabelWithIcon
                    cardScheme={transaction.cardScheme}
                    title={transaction.cardMask}
                />
            ),
            hidden: !transaction.cardMask
        },
        {
            key: 'captureMethod',
            label: translations().captureMethod,
            value: rootTranslations().constants.captureMethods[transaction.captureMethod]
        },
        {
            key: 'transactionCountry',
            label: translations().transaction.country,
            value: transaction.transactionCountry
        },
        {
            key: 'transactionCity',
            label: translations().transaction.city,
            value: transaction.transactionCity
        }
    ]

    if (newFields) {
        return {
            title: translations().details,
            fields: [...fields.slice(0, fieldsIndex), ...newFields, ...fields.slice(fieldsIndex)]
        }
    }

    return {title: translations().details, fields}
}

export const orderPOSSecondaryDetails = (transaction: POSTransactionType) => {
    return orderSecondaryDetails(
        transaction,
        [
            {
                key: 'cardType',
                label: translations().cardType,
                value:
                    rootTranslations().constants.cardType[transaction?.cardType] ||
                    transaction?.cardType,
                hidden: !transaction?.cardType
            },
            {
                key: 'cardCategory',
                label: translations().cardCategory,
                value: (() => {
                    switch (transaction?.isCorporateCard) {
                        case true:
                            return translations().corporate
                        case false:
                            return translations().personal
                        default:
                            return ''
                    }
                })(),
                hidden: isEmpty(transaction?.isCorporateCard)
            },
            {
                key: 'issuerRegion',
                label: translations().issuerRegion,
                value:
                    rootTranslations().constants.issuerRegion[transaction?.issuerRegion] ||
                    transaction?.issuerRegion,
                hidden: !transaction?.issuerRegion
            }
        ],
        6
    )
}
