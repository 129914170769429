import {get, set} from 'store-connector'

export const userHash = 'merchant-portal'
const options = { useUserHash: true, userHash }

export const storage = {
    get: (key: string) => {
        return get(key, options)
    },
    set: (key: string, value: string) => {
        set(key, value, options)
    },
    isEqual: (storageKey: string, key: string) => `${userHash}_${key}` === storageKey,
    isEmpty: (value: string) => {
        return !value || !JSON.parse(value)
    }
}
