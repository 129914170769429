import translations from './translations'

translations.add('es', {
    postLinkStatus: {
        all: 'Todos',
        ok: 'Ok',
        failed: 'Fallido'
    },
    columns: {
        amount: 'Importe',
        status: 'Estado',
        customer: 'Cliente',
        paymentMethod: 'Metodo de pago',
        orderNumber: 'Numero del pedido',
        date: 'Fecha',
        action: 'Acción'
    },
    actions: {
        charge: 'Cargo',
        refund: 'Reembolso',
        cancel: 'Cancelar'
    },
    viewDetails: 'Ver detalles',
    all: 'Todos',
    cancelPaymentInTheAmount: (amount) => `Cancelar 1 pago por importe de ${amount}?`,
    ok: 'Ok',
    cancel: 'Cancelar',
    successfullyCancelled: 'Pago cancelado con éxito',
    error: 'Error'
})
