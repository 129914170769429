import React, {Component, ErrorInfo, ReactNode} from 'react'
import {Result} from 'antd'
import * as Sentry from '@sentry/react'
import {Button} from '@/components'
import translations from './translations'
import {AuthStoreInterface} from '@/stores'
import {error} from 'dna-common'
import {userHash} from '@/services/storage'

interface ErrorBoundaryProps {
    children: ReactNode
    authStore?: AuthStoreInterface
}

interface ErrorBoundaryState {
    hasError: boolean
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    private authStore: AuthStoreInterface = null

    constructor(props: ErrorBoundaryProps) {
        super(props)
        this.state = {hasError: false}
        this.authStore = props.authStore
    }

    static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        return {hasError: true}
    }

    componentDidCatch(err: Error, errorInfo: ErrorInfo) {
        error(err, errorInfo, location.pathname)

        // Temporary functions. TODO remove later
        // Function to capture localStorage content by using merchant-portal userHash
        const getLocalStorageContent = (): Record<string, any> => {
            const localStorageData: Record<string, any> = {}
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i)
                if (key && key.startsWith(userHash)) {
                    try {
                        localStorageData[key] = JSON.parse(localStorage.getItem(key) || 'null')
                    } catch {
                        localStorageData[key] = localStorage.getItem(key) // Fallback for non-JSON values
                    }
                }
            }
            return localStorageData
        }

        // Function to capture the AuthStore instance state
        const getAuthStoreContent = (authStoreInstance: AuthStoreInterface): Record<string, any> => {
            const authStoreData: Record<string, any> = {}

            // check if authStoreInstance is an object and exists
            if (!authStoreInstance || typeof authStoreInstance !== 'object') {
                return authStoreData
            }

            // get only fields that are not functions
            for (const key in authStoreInstance) {
                if (authStoreInstance.hasOwnProperty(key) && typeof authStoreInstance[key] !== 'function') {
                    try {
                        authStoreData[key] = JSON.stringify(authStoreInstance[key]) // Convert each property to JSON
                    } catch {
                        authStoreData[key] = 'Unable to stringify' // Handle circular references or errors
                    }
                }
            }

            return authStoreData
        }

        // Combine and send data to Sentry
        const sendDataToSentry = (authStoreInstance: any) => {
            const localStorageContent = getLocalStorageContent()
            const authStoreContent = getAuthStoreContent(authStoreInstance)

            const combinedData = {
                localStorage: localStorageContent,
                authStore: authStoreContent,
                error: err,
                errorInfo
            }

            Sentry.captureMessage('An error was caught in the Error Boundary', {
                level: 'error',
                extra: combinedData
            })

            console.log('SENTRY DATA SENT', combinedData)
        }

        if (this.authStore) {
            console.log('Sending data to Sentry...')
            sendDataToSentry(this.authStore)

            // clear auth store and local storage
            this.authStore.clearStore()
            this.authStore.clearStorage()
        }
    }

    onGoHomeClick = () => {
        //clear auth store and local storage
        // navigate to the root url with reload
        if (this.authStore) {
            this.authStore.clearStore()
            this.authStore.clearStorage()
        }
        window.location.href = '/'
    }

    render() {
        if (this.state.hasError) {
            return <Result
                status="error"
                title={translations().errors.ourSupportTeamIsHereToHelp}
                extra={
                    <Button type="primary" key="console" onClick={this.onGoHomeClick}>
                        {translations().buttons.home}
                    </Button>
                }
            />
        }
        return this.props.children
    }
}
