import React from 'react'
import classNames from 'classnames'
import {ColumnsType} from 'antd/es/table'
import translations from '@/pages/PayByLink/translations/translations'
import {IPayByLinkStore} from '@/pages/PayByLink/IPayByLinkStore'
import {ActionButtons, LinkActionsMenu} from '@/pages/components'
import {DateTimeLabel, Icon, TableStatus} from '@/components'
import {PayByLinkStatus} from '@/constants'
import {PaymentLink} from '@/api'
import {getAmountWithCurrency} from '@/utils'
import {TagType} from '@/components/dumb/Tag/props'
import icons from '~/assets/icons'
import rootTranslations from '@/translations'
import {hasPermissions} from '@/stores/auth/services/utils'
import {PermissionsMap} from '@/stores/auth/constants/permissions-map'
import styles from './styles.scss'

export const getPayByLinkPaymentStatusName = (status: PayByLinkStatus): string => {
    switch (status) {
        case PayByLinkStatus.viewed:
            return rootTranslations().constants.payByLinkStatus.active
        default:
            return rootTranslations().constants.payByLinkStatus[status]
    }
}

export const getPayByLinkPaymentStatusTagType = (status: PayByLinkStatus): TagType => {
    switch (status) {
        case PayByLinkStatus.attempted:
        case PayByLinkStatus.cancelled:
        case PayByLinkStatus.expired:
            return TagType.Disabled
        case PayByLinkStatus.paid:
            return TagType.Processing
        case PayByLinkStatus.active:
        case PayByLinkStatus.viewed:
            return TagType.Active
        default:
            return TagType.Default
    }
}

export const getPayByLinkPaymentStatusIconType = (status: PayByLinkStatus): keyof typeof icons => {
    switch (status) {
        case PayByLinkStatus.expired:
        case PayByLinkStatus.cancelled:
            return 'close'
        case PayByLinkStatus.paid:
            return 'tick'
        case PayByLinkStatus.viewed:
        case PayByLinkStatus.active:
            return 'dot'
        case PayByLinkStatus.attempted:
            return 'warn'
        default:
            return 'close'
    }
}

export const getPayByLinkPaymentStatusExtraIconType = (
    status: PayByLinkStatus
): keyof typeof icons => {
    switch (status) {
        case PayByLinkStatus.viewed:
            return 'eye'
        default:
            return null
    }
}

export const getColumns = (payByLinkStore: IPayByLinkStore): ColumnsType<PaymentLink> => {
    return [
        {
            title: translations().amount,
            dataIndex: 'amount',
            key: 'amount',
            align: 'right' as any,
            width: 150,
            render: (item, object) => <b>{getAmountWithCurrency(item, object.currency)}</b>
        },
        {
            title: translations().status,
            dataIndex: 'status',
            key: 'status',
            align: 'left' as any,
            width: 150,
            render: (item) => {
                return (
                    <TableStatus
                        statusProps={{
                            status: getPayByLinkPaymentStatusName(item),
                            type: getPayByLinkPaymentStatusTagType(item),
                            iconType: getPayByLinkPaymentStatusIconType(item),
                            className: classNames({
                                [styles.active]:
                                    getPayByLinkPaymentStatusTagType(item) === TagType.Active
                            })
                        }}
                        extra={<Icon type={getPayByLinkPaymentStatusExtraIconType(item)} />}
                    />
                )
            }
        },
        {
            title: translations().description,
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: translations().dateCreated,
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (item) => <DateTimeLabel date={item} />
        },
        {
            title: translations().customer,
            dataIndex: 'customerName',
            key: 'customerName',
            render: (item) => (item ? item : '-')
        },
        {
            title: translations().expirationDate,
            dataIndex: 'expirationDate',
            key: 'expirationDate',
            render: (item) => <DateTimeLabel date={item} />
        },
        ...(hasPermissions([PermissionsMap.payment_links.full])
            ? [
                  {
                      title: translations().action,
                      dataIndex: '',
                      width: 180,
                      key: 'x',
                      render: (item, object) =>
                          getPayByLinkListActionButtons(object, payByLinkStore)
                  }
              ]
            : [])
    ]
}

export const getPayByLinkListActionButtons = (
    link: PaymentLink,
    payByLinkStore: IPayByLinkStore
) => {
    switch (link.status) {
        case PayByLinkStatus.paid:
            return (
                <ActionButtons
                    linkTitle={translations().paymentDetails}
                    onLinkClick={() => payByLinkStore.openOverviewModal(link)}
                    menu={<LinkActionsMenu paymentLink={link} />}
                />
            )
        default:
            return <ActionButtons menu={<LinkActionsMenu paymentLink={link} />} />
    }
}
