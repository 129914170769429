import {form} from 'back-connector'
import config from '~/config'
import {LoginRequest, LoginResponse, UpdateTokenRequest} from '@/stores/auth/models'
import {deleteWithAuth, putWithAuth, post} from '@/api'
import {LogoutRateLimiter, LogoutRateLimiterPayload} from '@/services/logout/LogoutRateLimiter'

export const login = (request: LoginRequest) =>
    post<LoginResponse>(
        '/oauth/client/oauth2/token',
        form({
            grant_type: 'password',
            scope: config.auth.user.scope,
            username: request.username,
            password: request.password,
            ...(request.merchantId ? {merchant_id: request.merchantId} : {}),
            ...(request.isDefault ? {isDefault: String(request.isDefault)} : {})
        })
    )

export const updateToken = ({
    grant_type,
    merchantId,
    twoFAcode,
    twoFAFrequencyId,
    twoFAStatusId,
    twoFATypeId
}: UpdateTokenRequest) => {
    const params = {
        grant_type,
        merchant_id: merchantId,
        '2fa_code': twoFAcode,
        '2fa_type_id': twoFATypeId,
        '2fa_status_id': twoFAStatusId,
        '2fa_frequency_id': twoFAFrequencyId
    }

    const filteredParams = Object.fromEntries(
        Object.entries(params).filter(([_, value]) => value !== undefined)
    )

    return putWithAuth<LoginResponse>('/oauth/oauth2/token', form(filteredParams))
}

export const fetchUserAsDefault = (request: Pick<LoginRequest, 'merchantId' | 'isDefault'>) =>
    putWithAuth<{message}>('/oauth/oauth2/user/default-merchant', request)

export const refreshTokenRequest = (refresh_token: string) =>
    post<LoginResponse>(
        '/oauth/client/oauth2/token',
        form({
            grant_type: 'refresh_token',
            scope: config.auth.user.scope,
            refresh_token: refresh_token
        })
    )

export const logout = (payload: LogoutRateLimiterPayload) => {
    if (!LogoutRateLimiter.trackAndCheckRateLimit(payload)) {
        throw new Error('Rate limit exceeded for logout request')
    }
    return deleteWithAuth<any>('/oauth/connect/token', true)
}
