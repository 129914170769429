import translations from './translations'

translations.add('el', {
    postLinkStatus: {
        all: 'Ολα',
        ok: 'Εντάξει',
        failed: 'Απέτυχε'
    },
    columns: {
        amount: 'Ποσό',
        status: 'Κατάσταση',
        customer: 'Πελάτης',
        paymentMethod: 'Μέθοδος πληρωμής',
        orderNumber: 'Αριθμός παραγγελίας',
        date: 'Ημερομηνία',
        action: 'Ενέργεια'
    },
    actions: {
        charge: 'Χρέωση',
        refund: 'Επιστροφή χρημάτων',
        cancel: 'Ακύρωση'
    },
    viewDetails: 'Δείτε λεπτομέρειες',
    all: 'Ολα',
    cancelPaymentInTheAmount: (amount) => `Ακύρωση 1 πληρωμής ύψους ${amount}?`,
    ok: 'Εντάξει',
    cancel: 'Ακύρωση',
    successfullyCancelled: 'Η πληρωμή ακυρώθηκε με επιτυχία',
    error: 'Error'
})
