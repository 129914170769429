import {OnlinePaymentStatus, POSPaymentStateStatus} from '@/constants'
import translations from './translations'

translations.add('en', {
    transactions: 'Transactions',
    tooltipText: {
        [POSPaymentStateStatus.successful]:
            'Includes transactions with the following statuses: Authorised, Charged, Refunded, Cancelled, Chargeback reversal, Second chargeback - lost',
        [OnlinePaymentStatus.charge]:
            'Includes transactions where the cardholder has been charged, but the payment is still being processed or confirmed for settlement',
        [OnlinePaymentStatus.auth]:
            'Includes pending transactions that are awaiting authorization or have been pre-authorized',
        [OnlinePaymentStatus.refund]: 'Includes refunded and credited transactions'
    },
    comparedToPreviousPeriod: (period) => `Compared to ${period}`
})
