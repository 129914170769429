import {ReactNode} from 'react'
import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    columns: {
        paymentMethod: string
        transactionDate: string
        settlementDate: string
        address: string
        postcode: string
        mid: string
        terminalNumber: string
        amount: string
        acquirerFee: string
        totalFee: string
        interchangeFee: string
        schemeFee: string
        processingFee: string
        gatewayFee: string
        authorisationFee: string
        blendedFee: string
        refundFee: string
        amountToSettle: string
        sortCode: string
        accountNumber: string
        operation: string
        transactionType: string
        transactionId: string
        merchantReference: string
        cardScheme: string
        cardMask: string
        cardholderName: string
        cardType: string
        isEuropeanCard: string
        issuerRegion: string
        issuerCountry: string
        isCorporateCard: string
        captureMethod: string
        settlementType: string
    }
    columnsPerDay: {
        transactionDate: string
        count: string
        amount: string
        acquirerFee: string
        amountToMerchant: string
        settlementDate: string
    }
    captureMethod: {
        posContactless: string
    }
    columnValues: {
        european: string
        nonEuropean: string
        corporate: string
        nonCorporate: string
        today: string
    }
    messages: {
        successExportMessage: ReactNode
    }
}>()
