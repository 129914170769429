import translations from './translations'

translations.add('ro', {
    postLinkStatus: {
        all: 'Toate',
        ok: 'Ok',
        failed: 'Eșuat'
    },
    columns: {
        amount: 'Sumă',
        status: 'Stare',
        customer: 'Client',
        paymentMethod: 'Metodă de plată',
        orderNumber: 'Număr comandă',
        date: 'Dată',
        action: 'Acțiune'
    },
    actions: {
        charge: 'Încărcare',
        refund: 'Restituire',
        cancel: 'Anulare'
    },
    viewDetails: 'Vezi detalii',
    all: 'Toate',
    cancelPaymentInTheAmount: (amount) => `Anulați o plată în valoare de ${amount}?`,
    ok: 'Ok',
    cancel: 'Anulare',
    successfullyCancelled: 'Plată anulată cu succes',
    error: 'Eroare'
})
