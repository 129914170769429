/**
 * Cookies
 **/
export const ACCEPT_ALL_COOKIES = 'cookiesAcceptAll'
export const ACCEPT_ESSENTIAL_COOKIES = 'cookiesEssential'
export const ACCEPT_INDIVIDUAL_COOKIES = 'cookiesIndividual'

/**
 * icons and radio
 **/
export const LOGO = 'logo'
export const DOWNLOAD_ICON = 'downloadDocIcon'
export const RADIO_GROUP = 'radio-group'
export const RADIO_GROUP_SHORTED = 'radio-group-shorted'
export const RADIO_GROUP_EXPANDED = 'radio-group-expanded'

/**
 * filtering
 **/
export const CLOSE_FILTER = 'closeFilter'
export const APPLY_FILTER = 'applyFilter'
export const CLEAR_FILTER = 'clearFilter'
export const CODE_INPUT = 'codeInput'
export const LANGUAGE = 'language'
export const PREVIOUS_RANGE_PICKER = 'previousRangePicker'
export const CURRENT_RANGE_PICKER = 'currentRangePicker'
export const EXPAND_FILTER = 'expand'
export const EXPANDED_FILTER_NAME = 'expanded_item'
export const RANGE_SELECT_FILTER = 'range-select'
export const RANGE_DATE_PICKER = 'range-date-picker'
export const SEARCH_BY_TEXT_FILTER = 'searchByText'
export const SELECT_BY_TITLE_FILTER = 'select-with-title'
export const SELECTED_BY_TITLE = 'selected-'

/**
 * percent
 **/
export const PERCENTAGE = 'percent'

/**
 * profile
 **/
export const OPEN_PROFILE = 'profile-open'
export const OPEN_PROFILE_ICON = 'profile-icon'
export const LOGOUT = 'logout'
export const EARTH_ICON = 'earthIcon'

/**
 * Refresh/resend
 **/
export const REFRESH = 'refresh-button'
export const RESEND = 'resend'

/**
 * Receipt
 **/
export const SEND_RECEIPT = 'sendReceipt'
export const EMAIL_TO_SEND_RECEIPT = 'emailReceipt'
export const RECEIPT = 'receipt'
export const CANCEL_RECEIPT = 'cancelReceipt'
export const CONFIRM_SEND_RECEIPT = 'confirmSendReceipt'

/**
 * user guide
 **/
export const SKIP_USER_GUIDE = 'skip'
export const START_TUTORIAL = 'startTutorial'

/**
 * exports
 **/
export const EXPORT_BUTTON = 'exportButton'
export const DOWNLOAD_BUTTON = 'downloadButton'
export const CANCEL_EXPORT = 'cancelExport'
export const SUBMIT_EXPORT = 'submitExport'
export const ONLINE_EXPORT = 'onlineExportButton'
export const CUSTOM_FIELDS_TO_EXPORT = 'fieldsToExport'
export const COLUMNS_TYPE_TO_EXPORT = 'columnsTypeToExport'
export const TABLE_EXPORT_BUTTON = 'exportTable'
export const ADD_COLUMN_TO_EXPORT = 'column-'

/**
 * menu
 **/
export const SELECT_MERCHANT = 'select'
export const MERCHANT_ITEM = 'merchant'
export const MENU = 'menu'

/**
 * no page
 **/
export const GO_HOME_BUTTON = 'goHome'

/**
 * card
 **/
export const VISA = 'visa'
export const AMEX = 'amex'
export const MASTERCARD = 'mastercard'
export const UNION_PAY = 'union_pay'
export const DINNERS_CLUB = 'dinnerClub'

/**
 * pagination
 **/
export const PAGINATION_LABEL = 'paginationLabel'
export const PAGINATION_SELECTED = 'pageSelected'
export const PREVIOUS_PAGE = 'prev'
export const NEXT_PAGE = 'next'

/**
 * payment methods
 **/
export const PAYMENT_METHOD_EXTRA_ICON = 'extra-icon'
export const PAYMENT_METHOD_SETTINGS = 'paymentMethodStoreSettings'
export const SWITCH_TERMINAL = 'switchTerminal'
export const APPLE_PAY = 'applePay'
export const GOOGLE_PAY = 'googlePay'
export const PAYPAL_PAY = 'paypalPay'
export const PAY_BY_BANK = 'pay_by_bank'
export const OPEN_BANKING = 'openBanking'
export const KLARNA = 'klarna'
export const CLICK_TO_PAY = 'clickToPay'
export const ASTRO_PAY = 'astropay'
export const ALI_PAY = 'aliPay'
export const WECHAT_PAY = 'wechatpay'

/**
 * payment methods - domain
 **/
export const ADD_DOMAIN = 'addDomain'
export const DOMAIN_INPUT = 'domain-input'
export const DELETE_DOMAIN = 'remove-domain'
export const DOMAIN_NAME = 'domain-name'
export const REASON_INPUT_DOMAIN = 'reason-input'

/**
 * payment methods - paypal
 **/
export const PAYPAL_SETTINGS_CUSTOMER_ADDRESS = 'customerAddress'
export const PAYPAL_SETTINGS_SHIPPING_ADDRESS = 'shippingAddress'
export const PAYPAL_SETTINGS_MERCHANT_ADDRESS = 'merchantAddress'
export const PAYPAL_TRANSACTION_AUTH = 'auth'
export const PAYPAL_TRANSACTION_CAPTURE = 'capture'
export const PAYPAL_CUSTOMIZE_SETTINGS = 'customizeSettings'

/**
 * charts overview/reports
 **/
export const CHART_BLOCK = 'chart'
export const AVERAGE_SUCCESS_AMOUNT = 'averageSuccessAmount'
export const DAYLY_SUCCESS_AMOUNT = 'dailySuccessAmount'
export const DAYLY_SUCCESS_TRANSACTIONS_NUMBER = 'dailySuccessTransactionsNumber'
export const ALL_POS_TRANS_CHART = 'posAllPosTransactionChart'
export const POS_CHARGED_TRANS_COMPARE_CHART = 'posChargedTransactionComparisonChart'
export const ONLINE_TRANSACTION_CHART = 'onlineTransactionsBarChart'
export const ONLINE_TRANS_COMPARE_CHART = 'onlineTransactionsComparisonBarChart'
export const POS_TRANSACTION_CHART = 'posTransactionsBarChart'
export const POS_COMPARE_BAR_CHART = 'posTransactionsComparisonBarChart'
export const CAROUSEL = 'carouselWidget'
export const CAROUSEL_SUBTITLE = 'carouselWidgetSubtitle'
export const CHART_TITLE_TEXT = 'chartTitle'
export const CHART_INFO = 'chartInfo'

/**
 * choose company
 **/
export const SELECT_COMPANY = 'selectCompany'
export const DEFAULT_COMPANY = 'defaultCompany'
export const SUBMIT_SELECTED_COMPANY = 'submitSelectedCompany'

/**
 * confirm email
 **/
export const OTP_CODE = 'code'
export const RESEND_OTP_CODE = 'resendOtp'
export const CONFIRM_EMAIL_BUTTON = 'confirmEmailButton'
export const CANCEL_CONFIRMATION_BUTTON = 'cancelConfirmation'
export const SUBMIT_CONFIRM_EMAIL = 'confirmEmailButton'

/**
 * login
 **/
export const LOGIN_EMAIL = 'email'
export const LOGIN_PASSWORD = 'password'
export const LOGIN_BY_ANOTHER_WAY = 'log-in-another-way'
export const SET_UP_NEW_PASSWORD = 'setUpNewPassword'
export const RESET_PASSWORD = 'resetPassword'
export const BACK_TO_LOGIN = 'backToLogin'


/**
 * reset password
 **/
export const EMAIL_TO_RESET = 'emailToReset'
export const SUBMIT_RESET = 'submitReset'
export const TRY_RESET_PASSWORD_AGAIN = 'resetPasswordAgain'
export const SET_NEW_PASSWORD_INPUT = 'newPassword'
export const CONFIRM_NEW_PASSWORD_INPUT = 'newPasswordConfirm'
export const SET_NEW_PASSWORD_BUTTON = 'setNewPasswordButton'
export const CONTINUE_LOGIN = 'continueButton'
export const SUBMIT_LOGIN_BUTTON = 'submitLogin'

/**
 * filter on online payments
 **/
export const FILTER_BUTTON = 'openFilterByClick'
export const PAYMENT_ORDER_NUMBER_EXPAND = 'orderNumberExpand'
export const PAYMENT_ORDER_NUMBER = 'paymentOrderNumber'
export const PAYER_DESCRIPTION_EXPAND = 'PayerDescriptionExpand'
export const PAYER_DESCRIPTION = 'payer_description'
export const PAYER_NAME_EXPAND = 'payer_name_expand'
export const PAYER_NAME = 'payer_name'
export const PAYER_EMAIL_EXPAND = 'payer_email_expand'
export const PAYER_EMAIL = 'payer_email'
export const AMOUNT_EXPAND = 'amount_expand'
export const AMOUNT_FROM = 'amountFrom'
export const AMOUNT_TO = 'amountTo'
export const CURRENCY_EXPAND = 'currencyExpand'
export const CURRENCY_ID = 'currencyId'
export const PAYMENT_METHOD_EXPAND = 'paymentMethodExpand'
export const PAYMENT_METHOD = 'paymentMethod'
export const CARD_EXPAND = 'cardExpand'
export const CARD = 'card'
export const CARD_MASK_FIRST = 'cardMaskFirst'
export const CARD_MASK_LAST = 'cardMaskLast'
export const CARD_SCHEME_EXPAND = 'cardSchemeExpand'
export const CARD_SCHEME = 'cardScheme'
export const STORE_EXPAND = 'storeExpand'
export const STORE = 'store'
export const ACCOUNT_EXPAND = 'accountExpand'
export const ACCOUNT_ID = 'accountId'
export const ACCOUNT_VALUE = 'accountValue'
export const RRN_EXPAND = 'rrnExpand'
export const RRN = 'receive_reference_number'
export const RRN_INPUT = 'receive_reference_number_input'
export const POSTLINK_EXPAND = 'postlinkExpand'
export const POST_LINK_STATUS = 'postLinkStatus'
export const DONATION_EXPAND = 'donationExpand'
export const DONATION_AMOUNT = 'donationAmount'
export const DONATION_AMOUNT_FROM = 'donationAmountFrom'
export const DONATION_AMOUNT_TO = 'donationAmountTo'
export const LOAD_AFTER = 'loadAfter'
export const DEBIT_CREDIT = 'debitCredit'
export const BALANCE = 'balance'
export const ACCOUNT = 'account'
export const MERCHANT = 'merchant'
export const USER = 'user'
export const REFERENCE = 'reference'
export const CHANNEL = 'channel'
export const TYPE = 'type'
export const FILTER = 'filter'
export const NAME = 'name'

/**
 * Transaction description
 **/
export const REFUND_BUTTON = 'refundButton'
export const CHARGE_PAYMENT_BUTTON = 'chargePayment'
export const CANCEL_PAYMENT_BUTTON = 'cancelPayment'
export const PROCESS_NEW_PAYMENT = 'processNewPayment'

/**
 * Transaction table
 **/
export const CARD_MASK_NUMBER_WITH_ICON = 'cardMaskNumberWithIcon'

/**
 * Timezone
 **/
export const TIME_ZONE = 'timeZoneSelect'
export const SET_TIME_ZONE = 'setTimeZone'
export const SAVE_TIME_ZONE = 'saveTimeZone'

/**
 * 2fa
 **/
export const CANCEL_TWO_FA_MODAL = 'cancelTwoFAModal'
export const SEND_VERIFICATION_CODE_BUTTON = 'sendVerificationCode'
export const DELETE_DEVICE = 'deleteDevice'
export const DEVICE_TABLE = 'devices'
export const DISABLE_TWO_FA_CODE = 'sendCode'
export const FREQUENCY_TWO_FA_CODE = 'frequency'
export const TWO_FA_STATUS = 'statusTwoFA'
export const TWO_FA_CANCEL = 'cancelTwoFA'
export const TWO_FA_BACK = 'backTwoFA'
export const TWO_FA_NEXT = 'nextTwoFA'
export const TWO_FA_UPDATE = 'updateTwoFA'
export const TWO_FA_SUBMIT = 'submitTwoFA'
export const TWO_FA_COPY = 'copied'
export const TWO_FA_LABEL = 'twoFAlabel'
export const CONFIGURE_ENABLE_TWO_FA_BUTTON = 'configureOrEnableTwoFA'
export const DISABLE_TWO_FA_BUTTON = 'disableTwoFA'
export const RECOVERY_CODE_INPUT = 'recoveryCodeInput'
export const CONFIRM_RECOVERY_BUTTON = 'confirmRecovery'
export const NO_VERIFICATION_CODE = 'noVerificationCode'

/**
 * settlements
 **/
export const SETTLEMENTS_PER_DAY = 'per-day'
export const SETTLEMENTS_PER_TRANSACTION = 'per-transaction'

/**
 * team management
 **/
export const TEAMMATE_EMAIL = 'emailOfTeammate'
export const TEAMMATE_FIRST_NAME = 'firstNameOfTeammate'
export const TEAMMATE_LAST_NAME = 'lastNameOfTeammate'
export const TEAMMATE_ROLE_SELECT = 'roleSelect'
export const TEAMMATE_ROLE_TYPE = 'financeOrCustom'
export const INVITE_TEAMMATE_BUTTON = 'inviteTeammateButton'
export const TEAMMATE_FILTER_BUTTON = 'teammateFilter'
export const TEAMMATE_EMAIL_FILTER = 'emailFilter'
export const TEAMMATE_FIRST_NAME_FILTER = 'firstNameFilter'
export const TEAMMATE_LAST_NAME_FILTER = 'lastNameFilter'
export const TEAMMATE_STORE_FILTER = 'storesChange'
export const TEAMMATE_STATUS_FILTER = 'statusesFilter'
export const TEAMMATE_AUTH_METHOD_FILTER = 'authMethodsFilter'
export const NO_ACCESS_RADIO_BUTTON = 'noAccess'
export const READ_ACCESS_RADIO_BUTTON = 'readAccess'
export const FULL_ACCESS_RADIO_BUTTON = 'fullAccess'
export const ADMIN_ROLE = 'adminRole'
export const RESTRICTED_ROLE = 'restrictedRole'
export const CANCEL_BUTTON = 'cancelButton'
export const INVITE_AS_RADIO = 'inviteRole'

/**
 * virtual terminal
 **/
export const EMAIL_SEND_VT_RECEIPT = 'email_VT_send_receipt'
export const DOWNLOAD_VT_RECEIPT = 'downloadVTReceiptButton'
export const CARD_NUMBER_INPUT = 'cardNumberInput'
export const CARD_HOLDER_INPUT = 'cardHolderInput'
export const CARD_EXPIRE_DATE_INPUT = 'expiryDateInput'
export const SWITCH_CVV = 'switchCVV'
export const CVV_INPUT = 'cvv'
export const CVV_MESSAGE = 'cvvMessage'
export const PAY_BY_VIRTUAL_TERMINAL = 'payByVirtualTerminal'
export const FRAUD_TITLE = 'fraudTitle'
export const FRAUD_DESCRIPTION = 'fraudText'
export const VT_BY_PHONE = 'byPhone'
export const VT_BY_EMAIL = 'byEmail'
export const VT_STORES = 'vt_stores'
export const VT_SELECT_STORE = 'selectVTStore'
export const VT_RECURRING = 'recurringVT'
export const VT_DESCRIPTION = 'descriptionVT'
export const VT_POSTAL_CODE = 'postalCodeInput'
export const VT_ADDRESS = 'addressInput'
export const VT_NEXT_BUTTON = 'nextButton'
export const PAYMENT_RESULT = 'paymentResultStatus'
export const VT_DOWNLOAD_RECEIPT = 'download-VT-receipt'
export const VT_ACCEPT_NEW_PAYMENT = 'acceptNewPayment'
export const VT_GO_BACK_TO_EDIT = 'goBackToEdit'

/**
 * payment link
 **/
export const COPY_ICON = 'copyIcon'
export const VIEW_ACTIONS_BUTTON = 'viewActions'
export const EDIT_PAYMENT_LINK = 'editPaymentLink'
export const SHARE_PAYMENT_LINK = 'sharePaymentLink'
export const CREATE_PAYMENT_LINK = 'createPaymentLink'
export const DEACTIVATE_PAYMENT_LINK = 'deactivatePaymentLink'
export const ACTION_LINK = 'link'
/**
 * NEW PAYMENT LINK MODAL
 **/
export const AMOUNT_INPUT = 'amountInput'
export const DEFAULT_CURRENCY = 'defaultCurrency'
export const RELOAD_ORDER_NUMBER = 'reloadOrderNumber'
export const SELECT_TRANSACTION_TYPE = 'transactionType'
export const TRANSACTION_ITEM = 'transaction-'
export const NEW_PAYMENT_LINK_BUTTON = 'newPaymentLinkButton'
export const NEW_PAYMENT_STORES = 'storesNewPayment'
export const NEW_PAYMENT_RECURRING = 'recurringNewPayment'
export const NEW_PAYMENT_CUSTOMER_NAME = 'customerName'
export const NEW_PAYMENT_DESCRIPTION = 'descriptionNewPayment'
export const NEW_PAYMENT_EXPIRATION_DATE = 'expirationDate'
export const NEW_PAYMENT_EXPIRATION_DATE_TYPES = 'expirationDateTypes'
export const TWENTY_FOUR_HOURS_EXPIRE_DATE = '24hour'
export const FOURTY_EIGHT_HOURS_EXPIRE_DATE = '48hour'
export const WEEK_EXPIRE_DATE = 'week1'
export const MONTH_EXPIRE_DATE = 'month1'
export const PLATFORM_MOBILE = 'mobile'
export const PLATFORM_LAPTOP = 'laptop'
export const ORDER_NUMBER_WITH_STATUS = 'orderNumberTitleWithStatus'
export const COPIED_PAYMENT_LINK = 'copiedPaymentLink'
export const SHARE_BY_EMAIL = 'share_by_email'
export const SHARE_BY_SMS = 'share_by_sms'
export const TRANSACTION_TITLE_AND_STATUS = 'transactionTitleWithStatus'
export const PAYMENT_LINK_VALUE_IN_FIELD = 'paymentLinkValue'
export const COPY_PAYMENT_LINK = 'copyLink'

/**
 * Submit form
 **/
export const SUBMIT_FILLED_FORM = 'submitFilledForm'

/**
 * Share
 **/
export const BACK_TO_LINK_DETAILS_BUTTON = 'backToLinkDetails'
export const RECIPIENT_EMAIL = 'recipientsEmail'
export const RECIPIENT_PHONE = 'recipientsPhone'
export const LINK_SHARE_EMAIL_MESSAGE_PREVIEW = 'linkShareByEmailMessagePreview'
export const LINK_SHARE_PHONE_MESSAGE_PREVIEW = 'linkShareByPhoneMessagePreview'