import {OnlinePaymentStatus, POSPaymentStateStatus} from '@/constants'
import translations from './translations'

translations.add('ro', {
    transactions: 'Tranzacții',
    tooltipText: {
        [POSPaymentStateStatus.successful]:
            'Include tranzacții cu următoarele statusuri: Autorizat, Încasat, Rambursat, Anulat, Reversare de contracost, A doua reversare de contracost - pierdută',
        [OnlinePaymentStatus.charge]:
            'Include tranzacții în care deținătorul cardului a fost debitat, dar plata este încă în curs de procesare sau confirmare pentru decontare',
        [OnlinePaymentStatus.auth]:
            'Include tranzacții în așteptare care așteaptă autorizarea sau au fost preautorizate',
        [OnlinePaymentStatus.refund]: 'Include tranzacții rambursate și creditate'
    },
    comparedToPreviousPeriod: (period) => `Comparativ cu ${period}`
})
