import translations from './translations'

translations.add('sl', {
    postLinkStatus: {
        all: 'Všetko',
        ok: 'Ok',
        failed: 'Zlyhalo'
    },
    columns: {
        amount: 'Množstvo',
        status: 'Stav',
        customer: 'Zákazník',
        paymentMethod: 'Platobná metóda',
        orderNumber: 'Číslo objednávky',
        date: 'Dátum',
        action: 'Akcia'
    },
    actions: {
        charge: 'Naúčtuj',
        refund: 'Vrátenie',
        cancel: 'Zruš'
    },
    viewDetails: 'Pozri detail',
    all: 'Všetko',
    cancelPaymentInTheAmount: (amount) => `Zruš 1 platbu v hodnote ${amount}?`,
    ok: 'Ok',
    cancel: 'Zruš',
    successfullyCancelled: 'Platba úspešne zrušená',
    error: 'Chyba'
})
