import React from 'react'
import {observer} from 'mobx-react'
import classNames from 'classnames'
import {Divider, Grid, List, Row, Typography} from 'antd'
import {PercentTrend} from '@/components/composite'
import {addZeroes, getAmountWithCurrency} from '@/utils'
import {IconTitle} from '@/components'
import {TransactionListItemProps} from './props'

import translations from './translations'
import styles from './styles.scss'

const {Title, Text} = Typography
const {Item} = List

export const TransactionSummaryListItem: React.FC<TransactionListItemProps> = observer(
    ({item, hasDivider, className, period, statusLabel, currency}) => {
        const screens = Grid.useBreakpoint()
        const isMobile = screens.xs
        const isTabOrLaptop = screens.sm && screens.md && !screens.lg && !screens.xl
        const tooltipForStatus = translations().tooltipText[item.status]

        return (
            <Item
                key={item.status}
                className={classNames(styles.item, className, {[styles.itemMobile]: isMobile})}
            >
                <div className={classNames(styles.content, {[styles.contentMobile]: isMobile})}>
                    <Row justify={'space-between'}>
                        <Text>
                            {statusLabel}
                            {tooltipForStatus && (
                                <IconTitle
                                    title={null}
                                    className={styles.infoIcon}
                                    tooltipText={tooltipForStatus}
                                />
                            )}
                        </Text>
                        {item.percent && item.percent !== 0 ? (
                            <PercentTrend
                                percent={item.percent}
                                tooltipText={translations().comparedToPreviousPeriod(period)}
                            />
                        ) : null}
                    </Row>
                    <Title className={styles.title} level={3}>
                        {getAmountWithCurrency(addZeroes(item.amount), currency)}
                    </Title>
                    <Text>
                        {translations().transactions}: {item.count || '-'}
                    </Text>
                </div>
                {!isTabOrLaptop && hasDivider && (
                    <Divider
                        className={classNames(styles.divider, {[styles.dividerMobile]: isMobile})}
                        type={isMobile ? 'horizontal' : 'vertical'}
                    />
                )}
            </Item>
        )
    }
)
