import {inject, injectable} from 'inversify'
import {action, makeObservable} from 'mobx'
import {THeaderStore} from '@/components'
import {AuthStoreInterface, AuthStoreSymbol} from '@/stores'
import {IPayByLinkStore} from '@/pages/PayByLink/IPayByLinkStore'
import {PayByLinkStoreSymbol} from '@/pages/PayByLink'
import {ProfileStoreSymbol} from '@/pages/Profile/Profile'
import {ProfileStoreInterface} from '@/pages/Profile'

@injectable()
export class HeaderStore implements THeaderStore {
    private _authStore: AuthStoreInterface
    private _payByLinkStore: IPayByLinkStore

    constructor(
        @inject(AuthStoreSymbol) authStore: AuthStoreInterface,
        @inject(PayByLinkStoreSymbol) payByLinkStore: IPayByLinkStore,
        @inject(ProfileStoreSymbol) profileStore: ProfileStoreInterface
    ) {
        this._authStore = authStore
        this._payByLinkStore = payByLinkStore

        profileStore.loadProfileData()

        makeObservable(this, {
            logout: action.bound,
            openNewPaymentLinkModal: action.bound
        })
    }

    logout = async () => {
        await this._authStore.logout(true, {
            trigger: 'user-logout'
        })
    }

    openNewPaymentLinkModal = () => {
        this._payByLinkStore.openNewPaymentLinkModal()
    }
}
