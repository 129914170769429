import {OnlinePaymentStatus, POSPaymentStateStatus} from '@/constants'
import translations from './translations'

translations.add('sl', {
    transactions: 'Transakcie',
    tooltipText: {
        [POSPaymentStateStatus.successful]:
            'Vključuje transakcije z naslednjimi statusi: Avtorizirano, Zaračunano, Povrnjeno, Preklicano, Povračilo bremenitve, Drugo povračilo bremenitve - izgubljeno',
        [OnlinePaymentStatus.charge]:
            'Vključuje transakcije, kjer je bila kartica zaračunana, vendar plačilo še vedno poteka ali čaka na potrditev za poravnavo',
        [OnlinePaymentStatus.auth]:
            'Vključuje čakajoče transakcije, ki čakajo na avtorizacijo ali so bile predhodno avtorizirane',
        [OnlinePaymentStatus.refund]: 'Vključuje povrnjene in pripisane transakcije'
    },
    comparedToPreviousPeriod: (period) => `Porovnané s ${period}`
})
