import React from 'react'
import {List} from 'antd'
import {Card, DateTimeLabel, Description, PaymentMethodOrMaskedCard, Text} from '@/components'
import {TitleWithStatus} from '@/components/composite/TitleWithStatus'
import {Currencies, OnlinePaymentStatus, CardSchemeType, PaymentMethodType} from '@/constants'
import {
    getOnlinePaymentStatusIconType,
    getOnlinePaymentStatusName,
    getOnlinePaymentStatusTagType
} from '@/pages/OnlinePayments/services'
import {getAmountWithCurrency} from '@/utils'
import translations from './translations'
import {MobileListProps} from './props'
import styles from './styles.scss'

export type ListItem = {
    key: string
    amount: string
    currency: Currencies
    status: OnlinePaymentStatus
    payerName: string
    invoiceId: string
    paymentMethod: PaymentMethodType
    createdDate: string
    description: string
    cardMask: string
    cardType: CardSchemeType
}

export const MobileOnlinePaymentList: React.FC<MobileListProps<ListItem>> = ({
    dataSource,
    listExtra,
    isLoading
}) => {
    return (
        <List
            loading={isLoading}
            itemLayout={'horizontal'}
            dataSource={dataSource || []}
            renderItem={(item) => {
                return (
                    <Card
                        key={item.key}
                        title={
                            <TitleWithStatus
                                statusProps={{
                                    status: getOnlinePaymentStatusName(item.status),
                                    type: getOnlinePaymentStatusTagType(item.status),
                                    iconType: getOnlinePaymentStatusIconType(item.status)
                                }}
                            >
                                <Text className={styles.listItemTitle}>
                                    {getAmountWithCurrency(item.amount, item.currency)}
                                </Text>
                            </TitleWithStatus>
                        }
                        extra={listExtra(item)}
                        className={styles.listItem}
                    >
                        <Description
                            dataSource={[
                                {
                                    label: translations().description,
                                    value: item.description,
                                    key: translations().description
                                },
                                {
                                    label: translations().customer,
                                    value: item.payerName,
                                    key: translations().customer
                                },
                                {
                                    label: translations().orderNumber,
                                    value: item.invoiceId,
                                    key: translations().orderNumber
                                },
                                {
                                    label: translations().paymentMethod,
                                    value: item.paymentMethod,
                                    key: translations().paymentMethod,
                                    render: () => {
                                        return (
                                            <PaymentMethodOrMaskedCard
                                                paymentMethod={item.paymentMethod}
                                                cardMask={item.cardMask}
                                                cardScheme={item.cardType}
                                            />
                                        )
                                    }
                                },
                                {
                                    label: translations().date,
                                    value: item.createdDate,
                                    key: translations().date,
                                    render: () => <DateTimeLabel date={item.createdDate} />
                                }
                            ]}
                        />
                    </Card>
                )
            }}
        />
    )
}
