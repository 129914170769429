import React from 'react'
import {Link} from 'react-router-dom'
import {ROUTES} from '@/router/routes'
import translations from './translations'

translations.add('es', {
    columns: {
        paymentMethod: 'Método de pago',
        transactionDate: 'Fecha de la transacción',
        settlementDate: 'Fecha de liquidación',
        address: 'Dirección',
        postcode: 'Código postal',
        mid: 'MID',
        terminalNumber: 'Terminal',
        amount: 'Importe',
        acquirerFee: 'Comisión de adquisición',
        totalFee: 'Comisión total',
        interchangeFee: 'Tasa de intercambio',
        schemeFee: 'Tasa del sistema de pago',
        processingFee: 'Tasa de tramitación',
        gatewayFee: 'Tasa de pasarela de pago',
        authorisationFee: 'Tasa de autorización',
        blendedFee: 'Tasa combinada',
        refundFee: 'Tasa de devolución',
        amountToSettle: 'Pagadero',
        sortCode: 'Código de clasificación',
        accountNumber: 'Número de cuenta',
        operation: 'Operación',
        transactionType: 'Tipo de transacción',
        transactionId: 'ID de transacción',
        merchantReference: 'Referencia comercial',
        cardScheme: 'Sistema de pago',
        cardMask: 'Máscara de tarjeta',
        cardholderName: 'Nombre del titular',
        cardType: 'Tipo de tarjeta',
        isEuropeanCard: 'Tarjeta europea',
        issuerRegion: 'Región de emisión',
        issuerCountry: 'País emisor',
        isCorporateCard: 'Tarjeta corporativa',
        captureMethod: 'Método de captura',
        settlementType: 'Tipo de Asentamiento'
    },
    columnsPerDay: {
        transactionDate: 'Fecha de la transacción',
        count: 'Número',
        amount: 'Importe',
        acquirerFee: 'Comisión de adquisición',
        amountToMerchant: 'Pagadero',
        settlementDate: 'Fecha de liquidación'
    },
    captureMethod: {
        posContactless: 'TPV Contactles'
    },
    columnValues: {
        european: 'Europeo',
        nonEuropean: 'no Europeo',
        corporate: 'Corporativo',
        nonCorporate: 'No Corporativo',
        today: 'Hoy'
    },
    messages: {
        successExportMessage: (
            <span>
                Su solicitud de descarga ha sido puesta en cola para ser procesada. Una vez que el
                documento esté listo, podrá descargarse desde la sección{' '}
                <Link to={ROUTES.exports}>Exportaciones</Link> .
            </span>
        )
    }
})
