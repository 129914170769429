import {DoughnutChartData} from '@/components'
import {ECOMClients, ECOMSummaryResponse, SummaryByStatusItem} from '@/stores/reports'
import {Colors, OnlinePaymentStatus, POSPaymentStateStatus} from '@/constants'
import clientDoughnutChartTranslations from '@/pages/Reports/components/ClientsDoughnutChart/translations'
import {getCardSchemeIconForChart} from '@/services/card-scheme'

export const posStateSummaryList: SummaryByStatusItem[] = [
    {
        status: POSPaymentStateStatus.successful,
        count: 0,
        amount: 0
    },
    {
        status: POSPaymentStateStatus.failed,
        count: 0,
        amount: 0
    }
]

export const getDefaultSummaryByStatusItem = (
    status: OnlinePaymentStatus
): SummaryByStatusItem => ({
    status,
    count: 0,
    amount: 0,
    percent: 0
})

export const getDoughnutClientsChartData = (data: ECOMClients): DoughnutChartData => ({
    labels: [
        clientDoughnutChartTranslations().newCustomers,
        clientDoughnutChartTranslations().returningCustomers
    ],
    total: data.new + data.returning,
    datasets: [
        {
            data: [data.new, data.returning],
            backgroundColor: [Colors.YELLOW, Colors.GREEN]
        }
    ]
})

export const getDoughnutChartAmountData = (
    data: ECOMSummaryResponse,
    parseLabel = (label: any) => label
): DoughnutChartData => ({
    labels: [...(data?.map((item) => parseLabel(item.value)) ?? [])],
    total: data?.reduce((acc, obj) => acc + obj.amount, 0),
    icons: getDoughnutIconsByLabel(data),
    datasets: [
        {
            data: [...(data?.map((item) => item.amount) ?? [])],
            backgroundColor: ['#69B6AA', '#F99B64', '#7ABFCC', '#E79C93', '#DADBDC', '#45A4FF']
        }
    ]
})

export const getDoughnutChartCountData = (
    data: ECOMSummaryResponse,
    parseLabel = (label: any) => label
): DoughnutChartData => ({
    labels: [...(data?.map((item) => parseLabel(item.value)) ?? [])],
    total: data?.reduce((acc, obj) => acc + obj.count, 0),
    icons: getDoughnutIconsByLabel(data),
    datasets: [
        {
            data: [...(data?.map((item) => item.count) ?? [])],
            backgroundColor: [
                Colors.LIGHT_GREEN,
                Colors.ORANGE,
                Colors.LIGHT_BLUE,
                Colors.PINK,
                Colors.LIGHT_GRAY,
                Colors.BLUE
            ]
        }
    ]
})

export const getDoughnutIconsByLabel = (data: ECOMSummaryResponse) => {
    if (!data) {
        return []
    }

    return data?.map((item) => {
        const cardSchemeIcon = getCardSchemeIconForChart(item.value)
        if (cardSchemeIcon) return cardSchemeIcon

        switch (item.value) {
            case 'card':
                return 'card_payments'
            case 'JPMORGAN CHASE BANK, N.A.':
                return 'jpmorgan_chase_logo'
            case "Sainsbury's Bank plc":
                return 'sainsburry_logo'
            case 'BARCLAYS':
                return 'barclays_logo'
            case 'EUROPAY INTERNATIONAL, S.A.':
                return 'europay_logo'
            case 'googlepay':
                return 'google_pay_logo'
            case 'ecospend':
                return 'ecospend_logo'
            case 'applepay':
                return 'apple_pay_logo'
            case 'paybybankapp':
                return 'pay_by_bank_logo'
            default:
                return 'card_payments'
        }
    })
}
