import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    postLinkStatus: {
        all: string
        ok: string
        failed: string
    }
    columns: {
        amount: string
        status: string
        customer: string
        paymentMethod: string
        orderNumber: string
        date: string
        action: string
    }
    actions: {
        charge: string
        refund: string
        cancel: string
    }
    viewDetails: string
    all: string
    cancelPaymentInTheAmount: (amount) => string
    ok: string
    cancel: string
    successfullyCancelled: string
    error: string
}>()
