import React, {useCallback} from 'react'
import {observer} from 'mobx-react'
import classNames from 'classnames'
import {Card} from 'antd'
import {useInjection} from 'dna-react-ioc'

import {LoadingState} from '@/types'
import {useScreenType} from '@/hooks'
import {Icon, PageHeader, TabsContainer, ExportButton, PaginationContainer} from '@/components'
import {AdaptiveTable, MobileTableControls} from '@/components/composite/table'

import {BaseTransactionType} from '@/types/transactions'
import {
    ExportsModalContainer,
    PaymentsFilterContainer,
    TransactionDetailsContainer
} from '@/components/containers/transaction'

import {addActionToColumns} from '@/utils/addActionToColumns'

import {ITransactionsPageStore} from './store'
import {TransactionsPageContainerProps} from './props'
import styles from './styles.scss'

export const TransactionsPageContainer = observer(
    <F, T extends BaseTransactionType>(props: TransactionsPageContainerProps<F, T>) => {
        const {
            store: _store,
            injectableIdentifier,
            title,
            description,
            exportTitle,
            columns,
            hideActions,
            filterFormId,
            getItemActions,
            getTransactionDetails,
            renderMobileItem,
            renderTitleWithStatus
        } = props

        const store = _store || useInjection<ITransactionsPageStore<F, T>>(injectableIdentifier)

        const {
            isInfinitePagination,
            tabsStore,
            filterStore,
            statusSelectStore,
            rangePickerStore,
            paginationStore,
            drawerStore,
            exportsModalStore,
            viewType,
            loadingState,
            transactions,
            isExportsAvailable,
            selectedTransaction,
            setViewType,
            setSelectedTransaction,
            reload
        } = store

        const {isMobile} = useScreenType()

        const isLoading = loadingState === LoadingState.LOADING
        const isLoadingMore = isInfinitePagination && paginationStore.pageNumber > 1 && isLoading

        const onLoadMore = useCallback(() => {
            if (store.loadingState === LoadingState.DONE && store.hasMoreData) {
                store.paginationStore.setPageNumber(store.paginationStore.pageNumber + 1)
            }
        }, [store])

        const openDrawer = (item: T) => {
            setSelectedTransaction(item)
            drawerStore.setOpen(true)
        }

        const renderPaymentsFilterContainer = (withExtra = false) => (
            <PaymentsFilterContainer
                formId={filterFormId}
                onReload={reload}
                filterStore={filterStore}
                statusSelectStore={statusSelectStore}
                rangePickerStore={rangePickerStore}
                loading={isLoading}
                extra={
                    withExtra &&
                    isExportsAvailable && (
                        <ExportButton
                            className={styles.exportButton}
                            onClick={() => exportsModalStore.setOpen(true)}
                        />
                    )
                }
            />
        )

        return (
            <>
                <PageHeader onBack={null} title={title} border={'none'}>
                    {description && <Card className={styles.card}>{description}</Card>}

                    {isMobile && (
                        <>
                            {renderPaymentsFilterContainer(true)}
                            <br />
                        </>
                    )}

                    <TabsContainer
                        store={tabsStore}
                        className={classNames(styles.headerTabs, {
                            [styles.loading]: isLoading
                        })}
                        tabBarExtraContent={
                            isMobile
                                ? null
                                : {
                                      right: renderPaymentsFilterContainer(true)
                                  }
                        }
                    />

                    {isMobile && (
                        <div className={styles.controls}>
                            <MobileTableControls setViewType={setViewType} viewType={viewType} />
                            {!isInfinitePagination && (
                                <PaginationContainer showOnlySizeChanger store={paginationStore} />
                            )}
                        </div>
                    )}
                </PageHeader>

                <AdaptiveTable
                    columns={addActionToColumns(columns, {
                        openDrawer,
                        getItemActions,
                        hideActions
                    })}
                    rowKey='id'
                    dataSource={transactions}
                    isMobile={isMobile}
                    isInfinitePagination={isInfinitePagination}
                    isLoadingMore={isLoadingMore}
                    mobileTableProps={{
                        isLoading: !isLoadingMore && isLoading,
                        renderItem: renderMobileItem,
                        renderItemTitle: (item) => renderTitleWithStatus(item, false),
                        renderItemExtra: (item) => (
                            <Icon onClick={() => openDrawer(item)} type={'ellipsis'} />
                        )
                    }}
                    tableMinWidth={1150}
                    tableViewType={viewType}
                    onSelectItem={openDrawer}
                    onLoadMore={onLoadMore}
                />

                {!isInfinitePagination && (
                    <PaginationContainer hideSizeChanger={isMobile} store={paginationStore} />
                )}

                <TransactionDetailsContainer
                    drawerStore={drawerStore}
                    {...(selectedTransaction
                        ? {
                              details: getTransactionDetails(selectedTransaction),
                              title: renderTitleWithStatus(selectedTransaction, true)
                          }
                        : {details: []})}
                />

                <ExportsModalContainer title={exportTitle} store={exportsModalStore} />
            </>
        )
    }
)
